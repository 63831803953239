<template>
  <div class="readme-article">
    <h2 id="管理员">6.管理员</h2>
    <h3 id="管理员列表">6.1管理员列表</h3>
    <p>
      查看管理员列表，包含管理员ID、用户名、姓名、添加时间等信息，支持新增、编辑和删除管理员账号。
    </p>
    <p>
      <img src="@/assets/img/card/6-1.png" />
    </p>
    <h3 id="角色管理">6.2角色管理</h3>
    <p>
      查看角色列表、角色权限配置情况，列表包含角色ID、角色名称、排序、添加时间等信息，支持新增、编辑和删除角色。
    </p>
    <p>
      <img src="@/assets/img/card/6-2.png" />
    </p>
    <p>
      <img src="@/assets/img/card/6-3.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card6-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>